@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

body {
  margin: 0;
}

@layer {
  .container {
    @apply px-4 mx-auto;
  }
}

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

:root {
  --linear: linear-gradient(180deg, #ffffff 55.88%, #fef9ed 100%);
  --linear2: linear-gradient(0deg,
      rgba(242, 195, 71, 0.1),
      rgba(242, 195, 71, 0.1)),
    linear-gradient(0deg, #ffffff, #ffffff);
  --primary: #f69621;
  --secondary: #f4f6f8;
  --neutral1: #d3d4d6;
  --brand-red: #eb202c;
}

body {
  font-family: "Poppins", sans-serif;
}

.ant-radio-button-checked {
  background-color: var(--primary);
}

.ant-radio-button-checked:hover {
  color: var(--primary);
}

.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
}

.otp-input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.loader_container {
  position: fixed;
  z-index: 9999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
}

.ant-spin .ant-spin-dot-item {
  background-color: var(--primary);
}

.ant-table-cell {
  color: #2e3147;
  font-weight: 400;
}

.btn-pressed {
  background: #eef0f4;
  border-radius: 15%;
  box-shadow: inset 9.91px 9.91px 20px #d9dade,
    inset -9.91px -9.91px 20px #ffffff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-select-selector {
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: 0px !important;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  width: 160px !important;
  height: 160px !important;
}

.w-\[fit-content\] {
  width: fit-content !important;
}

.ant-upload-list-item-container {
  width: 160px !important;
  height: 160px !important;
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
  background-color: transparent !important;
  border-inline-end: 0px;
}

.ant-switch {
  background-color: #e5e5e5 !important;
}

.ant-switch-checked {
  background-color: #4784ca !important;
}

*:focus {
  box-shadow: none !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.2);
  z-index: -1;
}

.product-cart {
  @apply w-full fixed flex flex-col bg-[var(--secondary)] border shadow-md;
  max-width: 350px;
  max-height: 450px;
  z-index: 999;
  bottom: 0px;
  right: 0;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.product-cart__header {
  @apply w-full flex flex-row flex-nowrap justify-between items-center border-b-2 shadow-md pt-2 pb-1 px-3;
}

.product-cart__header--close {
  @apply cursor-pointer text-3xl;
}

.product-cart__body {
  @apply w-full h-full overflow-y-auto overflow-x-hidden p-3 mb-5;
}

.product-cart__body::-webkit-scrollbar {
  @apply w-1;
}

.product-cart__body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--neutral1);
  border-radius: 10px;
}

.product-cart__body::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.product-cart__body::-webkit-scrollbar-thumb:hover {
  background: var(--neutral1);
}

.brand {
  @apply cursor-pointer;
  height: 100px;
}


/* Media queries */
@media only screen and (min-width:768px) {
  .product-cart {
    right: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .product-cart {
    max-width: 100%;
    max-height: 100%;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    bottom: 0;
    right: 0;
  }

  .product-cart__header {
    border-bottom: none;
  }

  .product-cart__header--close {
    text-align: right;
  }

  .product-cart__body {
    max-height: calc(100vh - 100px); /* Adjust as needed */
  }
}
.DefaultLayout{
  background-color: #f3f4f6 !important;
}
.capitalize {
  text-transform: capitalize !important;
}

.bebas-neue {
  font-family: "Bebas Neue", sans-serif;
  /* font-weight: 400;
  font-style: normal; */
}
